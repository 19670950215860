export default {
  SET_COUNTRIES(state, data) {
    state.countriesList = [
      {
        id: 1,
        country: 'Afghanistan',
        code: 'AF',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 2,
        country: 'Åland Islands',
        code: 'AX',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 3,
        country: 'Albania',
        code: 'AL',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 4,
        country: 'Algeria',
        code: 'DZ',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 5,
        country: 'American Samoa',
        code: 'AS',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 6,
        country: 'Andorra',
        code: 'AD',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 7,
        country: 'Angola',
        code: 'AO',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 8,
        country: 'Anguilla',
        code: 'AI',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 9,
        country: 'Antarctica',
        code: 'AQ',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 10,
        country: 'Antigua and Barbuda',
        code: 'AG',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 11,
        country: 'Argentina',
        code: 'AR',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 12,
        country: 'Armenia',
        code: 'AM',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 13,
        country: 'Aruba',
        code: 'AW',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 14,
        country: 'Australia',
        code: 'AU',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 15,
        country: 'Austria',
        code: 'AT',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 16,
        country: 'Azerbaijan',
        code: 'AZ',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 17,
        country: 'Bahamas',
        code: 'BS',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 18,
        country: 'Bahrain',
        code: 'BH',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 19,
        country: 'Bangladesh',
        code: 'BD',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 20,
        country: 'Barbados',
        code: 'BB',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 21,
        country: 'Belarus',
        code: 'BY',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 22,
        country: 'Belgium',
        code: 'BE',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 23,
        country: 'Belize',
        code: 'BZ',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 24,
        country: 'Benin',
        code: 'BJ',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 25,
        country: 'Bermuda',
        code: 'BM',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 26,
        country: 'Bhutan',
        code: 'BT',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 27,
        country: 'Bolivia, Plurinational State of',
        code: 'BO',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 28,
        country: 'Bonaire, Sint Eustatius and Saba',
        code: 'BQ',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 29,
        country: 'Bosnia and Herzegovina',
        code: 'BA',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 30,
        country: 'Botswana',
        code: 'BW',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 31,
        country: 'Bouvet Island',
        code: 'BV',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 32,
        country: 'Brazil',
        code: 'BR',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 33,
        country: 'British Indian Ocean Territory',
        code: 'IO',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 34,
        country: 'Brunei Darussalam',
        code: 'BN',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 35,
        country: 'Bulgaria',
        code: 'BG',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 36,
        country: 'Burkina Faso',
        code: 'BF',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 37,
        country: 'Burundi',
        code: 'BI',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 38,
        country: 'Cambodia',
        code: 'KH',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 39,
        country: 'Cameroon',
        code: 'CM',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 40,
        country: 'Canada',
        code: 'CA',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 41,
        country: 'Cape Verde',
        code: 'CV',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 42,
        country: 'Cayman Islands',
        code: 'KY',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 43,
        country: 'Central African Republic',
        code: 'CF',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 44,
        country: 'Chad',
        code: 'TD',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 45,
        country: 'Chile',
        code: 'CL',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 46,
        country: 'China',
        code: 'CN',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 47,
        country: 'Christmas Island',
        code: 'CX',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 48,
        country: 'Cocos (Keeling) Islands',
        code: 'CC',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 49,
        country: 'Colombia',
        code: 'CO',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 50,
        country: 'Comoros',
        code: 'KM',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 51,
        country: 'Congo',
        code: 'CG',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 52,
        country: 'Congo, the Democratic Republic of the',
        code: 'CD',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 53,
        country: 'Cook Islands',
        code: 'CK',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 54,
        country: 'Costa Rica',
        code: 'CR',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 55,
        country: "Côte d'Ivoire",
        code: 'CI',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 56,
        country: 'Croatia',
        code: 'HR',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 57,
        country: 'Cuba',
        code: 'CU',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 58,
        country: 'Curaçao',
        code: 'CW',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 59,
        country: 'Cyprus',
        code: 'CY',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 60,
        country: 'Czech Republic',
        code: 'CZ',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 61,
        country: 'Denmark',
        code: 'DK',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 62,
        country: 'Djibouti',
        code: 'DJ',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 63,
        country: 'Dominica',
        code: 'DM',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 64,
        country: 'Dominican Republic',
        code: 'DO',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 65,
        country: 'Ecuador',
        code: 'EC',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 66,
        country: 'Egypt',
        code: 'EG',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 67,
        country: 'El Salvador',
        code: 'SV',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 68,
        country: 'Equatorial Guinea',
        code: 'GQ',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 69,
        country: 'Eritrea',
        code: 'ER',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 70,
        country: 'Estonia',
        code: 'EE',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 71,
        country: 'Ethiopia',
        code: 'ET',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 72,
        country: 'Falkland Islands (Malvinas)',
        code: 'FK',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 73,
        country: 'Faroe Islands',
        code: 'FO',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 74,
        country: 'Fiji',
        code: 'FJ',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 75,
        country: 'Finland',
        code: 'FI',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 76,
        country: 'France',
        code: 'FR',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 77,
        country: 'French Guiana',
        code: 'GF',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 78,
        country: 'French Polynesia',
        code: 'PF',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 79,
        country: 'French Southern Territories',
        code: 'TF',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 80,
        country: 'Gabon',
        code: 'GA',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 81,
        country: 'Gambia',
        code: 'GM',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 82,
        country: 'Georgia',
        code: 'GE',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 83,
        country: 'Germany',
        code: 'DE',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 84,
        country: 'Ghana',
        code: 'GH',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 85,
        country: 'Gibraltar',
        code: 'GI',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 86,
        country: 'Greece',
        code: 'GR',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 87,
        country: 'Greenland',
        code: 'GL',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 88,
        country: 'Grenada',
        code: 'GD',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 89,
        country: 'Guadeloupe',
        code: 'GP',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 90,
        country: 'Guam',
        code: 'GU',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 91,
        country: 'Guatemala',
        code: 'GT',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 92,
        country: 'Guernsey',
        code: 'GG',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 93,
        country: 'Guinea',
        code: 'GN',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 94,
        country: 'Guinea-Bissau',
        code: 'GW',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 95,
        country: 'Guyana',
        code: 'GY',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 96,
        country: 'Haiti',
        code: 'HT',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 97,
        country: 'Heard Island and McDonald Islands',
        code: 'HM',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 98,
        country: 'Holy See (Vatican City State)',
        code: 'VA',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 99,
        country: 'Honduras',
        code: 'HN',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 100,
        country: 'Hong Kong',
        code: 'HK',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 101,
        country: 'Hungary',
        code: 'HU',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 102,
        country: 'Iceland',
        code: 'IS',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 103,
        country: 'India',
        code: 'IN',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 104,
        country: 'Indonesia',
        code: 'ID',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 105,
        country: 'Iran, Islamic Republic of',
        code: 'IR',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 106,
        country: 'Iraq',
        code: 'IQ',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 107,
        country: 'Ireland',
        code: 'IE',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 108,
        country: 'Isle of Man',
        code: 'IM',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 109,
        country: 'Israel',
        code: 'IL',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 110,
        country: 'Italy',
        code: 'IT',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 111,
        country: 'Jamaica',
        code: 'JM',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 112,
        country: 'Japan',
        code: 'JP',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 113,
        country: 'Jersey',
        code: 'JE',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 114,
        country: 'Jordan',
        code: 'JO',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 115,
        country: 'Kazakhstan',
        code: 'KZ',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 116,
        country: 'Kenya',
        code: 'KE',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 117,
        country: 'Kiribati',
        code: 'KI',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 118,
        country: "Korea, Democratic People's Republic of",
        code: 'KP',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 119,
        country: 'Korea, Republic of',
        code: 'KR',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 120,
        country: 'Kuwait',
        code: 'KW',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 121,
        country: 'Kyrgyzstan',
        code: 'KG',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 122,
        country: "Lao People's Democratic Republic",
        code: 'LA',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 123,
        country: 'Latvia',
        code: 'LV',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 124,
        country: 'Lebanon',
        code: 'LB',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 125,
        country: 'Lesotho',
        code: 'LS',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 126,
        country: 'Liberia',
        code: 'LR',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 127,
        country: 'Libya',
        code: 'LY',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 128,
        country: 'Liechtenstein',
        code: 'LI',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 129,
        country: 'Lithuania',
        code: 'LT',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 130,
        country: 'Luxembourg',
        code: 'LU',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 131,
        country: 'Macao',
        code: 'MO',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 132,
        country: 'Macedonia, the Former Yugoslav Republic of',
        code: 'MK',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 133,
        country: 'Madagascar',
        code: 'MG',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 134,
        country: 'Malawi',
        code: 'MW',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 135,
        country: 'Malaysia',
        code: 'MY',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 136,
        country: 'Maldives',
        code: 'MV',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 137,
        country: 'Mali',
        code: 'ML',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 138,
        country: 'Malta',
        code: 'MT',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 139,
        country: 'Marshall Islands',
        code: 'MH',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 140,
        country: 'Martinique',
        code: 'MQ',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 141,
        country: 'Mauritania',
        code: 'MR',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 142,
        country: 'Mauritius',
        code: 'MU',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 143,
        country: 'Mayotte',
        code: 'YT',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 144,
        country: 'Mexico',
        code: 'MX',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 145,
        country: 'Micronesia, Federated States of',
        code: 'FM',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 146,
        country: 'Moldova, Republic of',
        code: 'MD',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 147,
        country: 'Monaco',
        code: 'MC',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 148,
        country: 'Mongolia',
        code: 'MN',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 149,
        country: 'Montenegro',
        code: 'ME',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 150,
        country: 'Montserrat',
        code: 'MS',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 151,
        country: 'Morocco',
        code: 'MA',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 152,
        country: 'Mozambique',
        code: 'MZ',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 153,
        country: 'Myanmar',
        code: 'MM',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 154,
        country: 'Namibia',
        code: 'NA',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 155,
        country: 'Nauru',
        code: 'NR',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 156,
        country: 'Nepal',
        code: 'NP',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 157,
        country: 'Netherlands',
        code: 'NL',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 158,
        country: 'New Caledonia',
        code: 'NC',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 159,
        country: 'New Zealand',
        code: 'NZ',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 160,
        country: 'Nicaragua',
        code: 'NI',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 161,
        country: 'Niger',
        code: 'NE',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 162,
        country: 'Nigeria',
        code: 'NG',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 163,
        country: 'Niue',
        code: 'NU',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 164,
        country: 'Norfolk Island',
        code: 'NF',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 165,
        country: 'Northern Mariana Islands',
        code: 'MP',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 166,
        country: 'Norway',
        code: 'NO',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 167,
        country: 'Oman',
        code: 'OM',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 168,
        country: 'Pakistan',
        code: 'PK',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 169,
        country: 'Palau',
        code: 'PW',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 170,
        country: 'Palestine, State of',
        code: 'PS',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 171,
        country: 'Panama',
        code: 'PA',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 172,
        country: 'Papua New Guinea',
        code: 'PG',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 173,
        country: 'Paraguay',
        code: 'PY',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 174,
        country: 'Peru',
        code: 'PE',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 175,
        country: 'Philippines',
        code: 'PH',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 176,
        country: 'Pitcairn',
        code: 'PN',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 177,
        country: 'Poland',
        code: 'PL',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 178,
        country: 'Portugal',
        code: 'PT',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 179,
        country: 'Puerto Rico',
        code: 'PR',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 180,
        country: 'Qatar',
        code: 'QA',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 181,
        country: 'Réunion',
        code: 'RE',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 182,
        country: 'Romania',
        code: 'RO',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 183,
        country: 'Russian Federation',
        code: 'RU',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 184,
        country: 'Rwanda',
        code: 'RW',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 185,
        country: 'Saint Barthélemy',
        code: 'BL',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 186,
        country: 'Saint Helena, Ascension and Tristan da Cunha',
        code: 'SH',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 187,
        country: 'Saint Kitts and Nevis',
        code: 'KN',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 188,
        country: 'Saint Lucia',
        code: 'LC',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 189,
        country: 'Saint Martin (French part)',
        code: 'MF',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 190,
        country: 'Saint Pierre and Miquelon',
        code: 'PM',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 191,
        country: 'Saint Vincent and the Grenadines',
        code: 'VC',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 192,
        country: 'Samoa',
        code: 'WS',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 193,
        country: 'San Marino',
        code: 'SM',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 194,
        country: 'Sao Tome and Principe',
        code: 'ST',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 195,
        country: 'Saudi Arabia',
        code: 'SA',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 196,
        country: 'Senegal',
        code: 'SN',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 197,
        country: 'Serbia',
        code: 'RS',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 198,
        country: 'Seychelles',
        code: 'SC',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 199,
        country: 'Sierra Leone',
        code: 'SL',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 200,
        country: 'Singapore',
        code: 'SG',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 201,
        country: 'Sint Maarten (Dutch part)',
        code: 'SX',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 202,
        country: 'Slovakia',
        code: 'SK',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 203,
        country: 'Slovenia',
        code: 'SI',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 204,
        country: 'Solomon Islands',
        code: 'SB',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 205,
        country: 'Somalia',
        code: 'SO',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 206,
        country: 'South Africa',
        code: 'ZA',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 207,
        country: 'South Georgia and the South Sandwich Islands',
        code: 'GS',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 208,
        country: 'South Sudan',
        code: 'SS',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 209,
        country: 'Spain',
        code: 'ES',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 210,
        country: 'Sri Lanka',
        code: 'LK',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 211,
        country: 'Sudan',
        code: 'SD',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 212,
        country: 'Suriname',
        code: 'SR',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 213,
        country: 'Svalbard and Jan Mayen',
        code: 'SJ',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 214,
        country: 'Swaziland',
        code: 'SZ',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 215,
        country: 'Sweden',
        code: 'SE',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 216,
        country: 'Switzerland',
        code: 'CH',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 217,
        country: 'Syrian Arab Republic',
        code: 'SY',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 218,
        country: 'Taiwan, Province of China',
        code: 'TW',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 219,
        country: 'Tajikistan',
        code: 'TJ',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 220,
        country: 'Tanzania, United Republic of',
        code: 'TZ',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 221,
        country: 'Thailand',
        code: 'TH',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 222,
        country: 'Timor-Leste',
        code: 'TL',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 223,
        country: 'Togo',
        code: 'TG',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 224,
        country: 'Tokelau',
        code: 'TK',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 225,
        country: 'Tonga',
        code: 'TO',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 226,
        country: 'Trinidad and Tobago',
        code: 'TT',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 227,
        country: 'Tunisia',
        code: 'TN',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 228,
        country: 'Turkey',
        code: 'TR',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 229,
        country: 'Turkmenistan',
        code: 'TM',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 230,
        country: 'Turks and Caicos Islands',
        code: 'TC',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 231,
        country: 'Tuvalu',
        code: 'TV',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 232,
        country: 'Uganda',
        code: 'UG',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 233,
        country: 'Ukraine',
        code: 'UA',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 234,
        country: 'United Arab Emirates',
        code: 'AE',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 235,
        country: 'United Kingdom',
        code: 'GB',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 236,
        country: 'United States',
        code: 'US',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 237,
        country: 'United States Minor Outlying Islands',
        code: 'UM',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 238,
        country: 'Uruguay',
        code: 'UY',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 239,
        country: 'Uzbekistan',
        code: 'UZ',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 240,
        country: 'Vanuatu',
        code: 'VU',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 241,
        country: 'Venezuela, Bolivarian Republic of',
        code: 'VE',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 242,
        country: 'Viet Nam',
        code: 'VN',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 243,
        country: 'Virgin Islands, British',
        code: 'VG',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 244,
        country: 'Virgin Islands, U.S.',
        code: 'VI',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 245,
        country: 'Wallis and Futuna',
        code: 'WF',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 246,
        country: 'Western Sahara',
        code: 'EH',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 247,
        country: 'Yemen',
        code: 'YE',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 248,
        country: 'Zambia',
        code: 'ZM',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 249,
        country: 'Zimbabwe',
        code: 'ZW',
        created_at: '2024-03-01 07:09:41',
        updated_at: null,
      },
      {
        id: 250,
        country: 'sdf',
        code: '23',
        created_at: '2024-05-27 10:09:17',
        updated_at: '2024-05-27 10:09:17',
      },
      {
        id: 251,
        country: 'Ismar',
        code: 'IS',
        created_at: '2024-05-29 06:51:52',
        updated_at: '2024-05-29 06:51:52',
      },
      {
        id: 253,
        country: 'Niderland',
        code: 'NL',
        created_at: '2024-06-11 08:48:21',
        updated_at: '2024-06-11 08:48:21',
      },
    ]
  },
}
