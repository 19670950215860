import axios from 'axios'
import JwtService from '@/core/services/jwt.service'
import router from './router'

/**
 * Service to call HTTP request via Axios
 */
// const API_URL = 'http://localhost:3000'
const API_URL = 'https://admin.tumar.com'

axios.defaults.baseURL = API_URL + '/api'
axios.defaults.headers.common[
  'Authorization'
] = `Bearer ${JwtService.getToken()}`
axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.response.status === 401) {
      router.push({ name: 'login' })
    }
    return Promise.reject(error)
  }
)

export default axios
